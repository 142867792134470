import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import CallToAction from '../components/CallToAction';
import Hero from '../components/Hero';
import MetaData from '../components/MetaData';
import StaffList from '../components/StaffList';
import TextColumns from '../components/TextColumns';
import TextSections from '../components/TextSections';
import QuoteSlider from '../components/QuoteSlider';

const AboutUs = ({ data: { wordpressPage } }) => {

	let {
		title,
		acf: {
			seo_title,
			seo_description,
			seo_images,
			hero: {
				hero_title,
				hero_text,
				hero_image,
				hero_image_mobile,
			},
			text_sections: {
				text_sections
			},
			testimonials,
			team_members: {
				team_members_title,
				team_members
			},
			text_columns,
			call_to_action_title,
			call_to_action_text,
			call_to_action_link_2,
			call_to_action_link_text,
		}
	} = wordpressPage;

	return (
		<Fragment>
			<MetaData
				title={seo_title || title}
				desciption={seo_description}
				images={seo_images}
			/>

			<Hero
				title={hero_title}
				text={hero_text}
				image={hero_image}
				imageMobile={hero_image_mobile}
			/>

			<TextSections
				sections={text_sections}
			/>

			<QuoteSlider
				items={testimonials}
			/>

			<StaffList
				title={team_members_title}
				staff={team_members}
			/>

			<TextColumns
				title={text_columns.text_cols_title}
				columns={text_columns.text_cols_columns}
			/>

			<CallToAction
				title={call_to_action_title}
				text={call_to_action_text}
				link={call_to_action_link_2}
				linkText={call_to_action_link_text}
			/>
		</Fragment>
	);
}

export const pageQuery = graphql`
	{
		wordpressPage(slug: {eq: "about-us"}) {
			title
			acf {
				seo_title
				seo_description
				seo_images {
					image {
						source_url
					}
				}
				hero {
					hero_title
					hero_text
					hero_image {
						sizes {
							large {
								source_url
								alt_text
							}
						}
					}
					hero_image_mobile {
						sizes {
							large {
								source_url
								alt_text
							}
						}
					}
				}
				text_sections {
					text_sections {
						title
						text
						image {
							sizes {
								large {
									source_url
									alt_text
								}
							}
						}
					}
				}
				testimonials {
					quote
					quote_author
				}
				team_members {
					team_members_title
					team_members {
						title
						role
						text
						image {
							source_url
						}
					}
				}
				text_columns {
					text_cols_title
					text_cols_columns {
						title 
						text
					}
				}
				call_to_action_title
				call_to_action_text
				call_to_action_link_2
				call_to_action_link_text
			}
		}
	}

`;

export default AboutUs;
