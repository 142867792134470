import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import FadeEntry from './FadeEntry';
import Title from './Title';

const StaffList = ({ title, staff }) => {

	if(!staff || !staff.length) return null;

	return(
		<Outer>
			{ title ? (
				<Container>
					<FadeEntry>
						<Title
							as={'h3'}
							dangerouslySetInnerHTML={{ __html: title }}
							className={'staff-list__title'}
						/>
					</FadeEntry>
				</Container>
			) : null }

			<div className="staff-list__inner">
				{ staff.map(({ title, role, text, image }) => (
					<FadeEntry
						className={'staff-item'}
						key={`staff-list-${title}`}
					>
						<Container>
							{ image ? (
								<div className="staff-item__img">
									<img
										src={image.source_url}
										alt={title}
									/>
								</div>
							) : null }

							<div className="staff-item__info">
								<Title
									as={'h4'}
									dangerouslySetInnerHTML={{ __html: title }}
								/>

								{ role ? (
									<strong
										className="staff-item__role"
										dangerouslySetInnerHTML={{ __html: role }}
									/>
								) : null }

								{ text ? (
									<div
										dangerouslySetInnerHTML={{ __html: text }}
										className={'staff-item__text'}
									/>
								) : null }
							</div>
						</Container>
					</FadeEntry>
				)) }
			</div>
		</Outer>
	);
}

const Outer = styled.div`
	padding: 80px 0 0;
	
	.staff-list__title {
		margin-bottom: 20px;
		font-size: ${({ theme }) => theme.fontSize.titleLg};
	}
	
	.staff-item {
		position: relative;
		
		&:before {
			content: '';
			position: absolute;
			top: -35%;
			left: 0;
			width: 54%;
			height: 0;
			padding-bottom: 64%;
			background-image: url('/images/oval-left.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center left;
			z-index: -1;
		}
	
		${Container} {
			display: flex;
		}
		
		&__img {
			width: 49%;
			border-radius: ${({ theme }) => theme.radius.card};
			overflow: hidden;
			
			img {
				width: 100%;
			}
		}
		
		&__role {
			display: block;
			margin-top: 10px;
			opacity: .8;
			line-height: 150%;
		}
		
		&__info {
			flex: 1;
			padding-left: 55px;
		}
		
		&__text {
			margin-top: 20px;
			opacity: .8;
		}
		
		+* {
			margin-top: 140px;
		}
		
		&:nth-of-type(even) {
			${Container} {
				flex-direction: row-reverse;
			}
			
			&:before {
				top: -28%;
				left: initial;
				right: 0;
				background-image: url('/images/oval-right.svg');
				background-position: center right;
			}
			
			.staff-item__info {
				padding: 0 55px 0 0;
			}
		}
	}
	
	@media (max-width: 991px) {
		.staff-item {
			&:before {
				top: -14%;
			}
			
			&:nth-of-type(even) {
				&:before {
					top: -10%;
				}
			}
		}
	}
	
	@media (max-width: 767px) {
		.staff-item {
			&__info {
				padding-left: 35px;
			}
			
			&:nth-of-type(even) {
				.staff-item__info {
					padding: 0 35px 0 0;
				}
			}
			
			+* {
				margin-top: 60px;
			}
		}
	}
	
	@media (max-width: 599px) {
		.staff-item {
			&__img {
				width: 100%;
			}
			
			&__info {
				padding: 25px 0 0;
			}
			
			&:nth-of-type(even) {
				.staff-item__info {
					padding: 25px 0 0 0;
				}
			}
			
			${Container} {
				flex-wrap: wrap;
			}
		}
	}
`;

export default StaffList;
