import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import styled from 'styled-components';

import Quote from './Quote';

const sliderSettings = {
	slidesToShow: 1,
	dots: true,
	arrows: false,
	infinite: false,
	speed: 500,
	fade: true,
	cssEase: 'linear',
	adaptiveHeight: true,
	autoplay: false,
	autoplaySpeed: 4000,
}

const QuoteSlider = ({ items }) => {
	if(!items || !items.length) return null;

	return(
		<StyledSlider {...sliderSettings}>
			{items.map(item => {
				const quote = item.quote;
				const author = item.author || item.quote_author;
				return(
					<Quote quote={quote} author={author} key={author} />
				);
			})}
		</StyledSlider>
	)
}

QuoteSlider.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		quote: PropTypes.string,
		author: PropTypes.string,
	}))
}

const StyledSlider = styled(Slider)`
	.slick-dots {
		position: absolute;
		width: 100%;
		bottom: 30px;
		display: flex!important;
		justify-content: center;

		li {
			opacity: 0.7;
			margin: 6px;

			button {
				width: 9px;
				height: 9px;
				padding: 0;
				border: 0;
				border-radius: 50%;
				background: #fff;
				font-size: 0;
			}

			&.slick-active {
				opacity: 1;

				button {
					background: ${({ theme }) => theme.color.brand};
				}
			}
		}
	}
`;

export default QuoteSlider;
